/*
Menu working with overlays, z-index hygiene is relatively important:
0-9: reserved for the editor
10: semi opaque background, the ones that can be clicked to close the menu
11: action strip and expanded menu , basically the menu
20: toast messages, that needs to appear above all the rest of the UI.

*/
/* the action strip itself, it is positioned dynamically via the element style directly  */
.note-menu-actions {
  display: flex;
  gap: 2px;
  position: relative;
  float: left;
  background: transparent;
  padding: 2px 2px;
  z-index: 11;
}

/* button in the action strip */
.note-menu-button {
  padding: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 0;
  background: transparent;
  border-radius: var(--radius-large);
  min-width: 32px;
  min-height: 32px;
  z-index: 11;
  color: var(--color-text-secondary);
}

.note-menu-button:hover,
.note-menu-button:focus,
.note-menu-button.expanded {
  outline: 0;
  background: var(--color-bg-tertiary);
}

.note-menu-button.active {
  color: var(--color-text-accent);
}

.note-menu-button img {
  display: block;
}

.note-menu-button.folder-icon {
  padding: 4px 8px;
}

.menu-expanded {
  display: flex;
  flex-direction: column;
  position: relative;
  min-width: 180px;
  border: 1px solid var(--color-border-secondary);
  background: var(--color-bg-primary);
  box-shadow: var(--shadow-light);
  padding: 2px 2px 10px 2px;
  border-radius: var(--radius-smooth);
  z-index: 11;
  font-size: 14px;
}

/* a menu item */
.menu-item,
button.menu-item {
  display: flex;
  flex-direction: row;
  gap: 4px;
  align-items: center;
  padding: 6px 12px;
  height: 36px;
  border-radius: var(--radius-medium);
  text-align: left;
  font-size: 14px;
  border: none;
  background-color: var(--color-bg-primary);
  color: var(--color-text-primary);
}

.menu-item:hover,
button.menu-item:hover {
  background-color: var(--color-bg-tertiary);
}

.menu-item>input {
  margin-right: 8px;
  width: 16px;
  cursor: pointer;
}

.parseWithAI-button {
  background-color: rgba(0, 160, 173, 0.1);
  border: 1px solid rgba(0, 160, 173, 0.5);
  border-radius: 4px;
  height: 24px;
  font-size: 12px;
  margin-top: -2px;
  margin-left: 10px;
  margin-right: 4px;
  filter: brightness(0) saturate(100%) invert(99%) sepia(70%) saturate(1257%) hue-rotate(120deg) brightness(87%) contrast(94%);
}

.parseWithAI-button:hover {
  opacity: 0.8;
}

.audio-menu-button {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 0;
  background: transparent;
  border-radius: var(--radius-medium);
  color: var(--color-bg-accent-primary);
}

.audio-menu-button:hover,
.audio-menu-button:focus,
.audio-menu-button.expanded,
.audio-menu-button.active {
  outline: 0;
  opacity: 0.8;
}

button.folder {
  padding-left: 8px;
}

/* little folder imagets on the left */
.folder img,
.folder span {
  display: block;
  margin-left: 8px;
}

/* used for the list of folders right now */
.note-menu-list {
  display: flex;
  flex-direction: column;
  max-height: 50vh;
  padding: 4px 4px;
  overflow-y: auto;
}

/* new folder related elements */
.newFolderContainer {
  display: flex;
  flex-direction: row;
  position: relative;
}

.newFolderContainer input,
.newFolderContainer input:focus {
  background-color: var(--color-bg-sub);
  height: 34px;
  border-radius: 4px;
  margin: 0px 8px;
  color: var(--color-text-primary);
  text-indent: 8px;
  width: calc(100% - 16px);
  outline: none !important;
  border: none;
}

.newFolderContainer input:focus {
  box-shadow: 0 0 0 3px var(--color-bg-accent-secondary);
}

button.enter-button,
button.enter-button:hover {
  height: 20px;
  position: absolute;
  background: transparent;
  border: none;
  outline: none;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
}

.enter-button svg {
  margin: 0px;
  width: 20px;
  height: 20px;
  object-fit: cover;
  background: var(--color-bg-tertiary);
  border-radius: var(--radius-medium);
  color: var(--color-text-secondary);
}

/* created at, updated at in the more menu */
.menu-info {
  padding: 0px 12px;
  width: 100%;
  text-align: left;
  font-size: 12px;
  color: var(--color-text-tertiary);
}

.note-menu-mobile {
  position: absolute;
  right: 8px;
  top: 1px;
}

.note-menu-button-mobile {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 0;
  background: transparent;
  border-radius: var(--radius-medium);
  width: 32px;
  height: 32px;
  padding: 0;
  color: var(--color-text-tertiary);
}

/* Without the media query, on mobile devices without a pointer device the
button gets stuck in a hover state when you tap it.
See https://stackoverflow.com/questions/17233804/how-to-prevent-sticky-hover-effects-for-buttons-on-touch-devices */
@media (hover: hover) {
  .note-menu-button-mobile:hover {
    background: var(--color-bg-secondary);
  }
}

.menu-icon-spacer {
  padding-left: 8px;
}