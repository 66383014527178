.selected-link {
  color: var(--color-text-accent);
  text-decoration: none;
  display: flex;
  cursor: pointer;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
  height: 36px;
  padding: 0 4px;
  margin: 0px 0;
  border-radius: 6px;
  font-weight: var(--highlight-font-weight);
  background-color: transparent;
  transition: var(--transition-light);
  border: 0;
  width: 100%;
  line-height: 1.4em;
  position: relative;
  gap: 8px;
}

.selected-link:hover {
  background-color: var(--color-bg-tertiary);
}
.selected-link:focus {
  outline: none;
}

.selected-link:focus:not(:hover)::before,
.selected-link:focus:not(:hover)::after {
  opacity: 0;
  
}
