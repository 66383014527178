/* layout used for global values */
* {
  box-sizing: border-box;
}

:root {
  /* used for the toggle */
  --handle-size: 10px;
}

html {
  height: -webkit-fill-available;
  overscroll-behavior: none; /* Fixes an issue with iOS PWA: https://linear.app/ideaflow/issue/ENT-1839/ios-pwa-installed-app-locks-scrolling-after-certain-actions */
}

body {
  font-family: -apple-system, BlinkMacSystemFont, "Helvetica : Neue", Helvetica, Arial, sans-serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.45;
  letter-spacing: 0.005rem;
  color: var(--color-text-primary);
  text-rendering: optimizeLegibility;
  background-color: var(--color-bg-primary);
  margin: 0;
  padding: 0;
  height: 100vh;
  height: -webkit-fill-available;
  width: 100vw;
  max-width: 100%;
  overflow: hidden;
}

#__next {
  height: 100vh;
  height: -webkit-fill-available;
  width: 100vw;
  max-width: 100%;
  position: fixed;
  top: 0;
  bottom: 0;
  overflow: hidden;
}

body {
  --header-height: 56px;

  background-color: var(--color-bg-primary);

  --highlight-font-weight: normal;
  --transparent: #ffffff00;

  --color-text-strong: #000000;
  --color-text-primary: #1c2323;
  --color-text-secondary: #677375;
  --color-text-tertiary: #aeb3b4;
  --color-text-accent: #009fbb;
  --color-text-positive: #08a46c;
  --color-text-negative: #d64545;
  --color-text-searchHighlight: #1c2323;

  --color-button-text: #ffffff;
  --color-button-bg: #4dc2d6;
  --color-button-bg-secondary: #acd7de;
  --color-button-bg-recording: #d64545;

  --color-bg-primary: #fcfcfc;
  --color-bg-secondary: #f1f4f4;
  --color-bg-tertiary: #e8ecec;
  --color-bg-sub: var(--color-bg-secondary);
  --color-bg-elevated: var(--color-bg-primary);
  --color-bg-public: #fcfcfc;

  --color-bg-audio-insert: #acd7de;
  --color-waveform-visualizer: #414949;

  --color-bg-accent-primary: #19bbd7;
  --color-bg-accent-secondary: #4dc2d640;
  --color-bg-accent-tertiary: #4dc2d630;
  --color-bg-accent-quaternary: #4dc2d610;

  --color-bg-positive-primary: #08a46c;
  --color-bg-positive-secondary: #08a46c50;
  --color-bg-positive-tertiary: #08a46c15;

  --color-bg-negative-primary: #e40c0c;
  --color-bg-negative-secondary: #e40c0c50;
  --color-bg-negative-tertiary: #e40c0c15;

  --color-bg-ok: #08a46c;
  --color-bg-offline: #eabd5d;
  --color-bg-warning: #be812e;
  --color-bg-error: #e40c0c;

  --color-border-primary: #aeb3b4;
  --color-border-secondary: #00000020;
  --color-border-secondary-solid: #ccd3d4;

  --color-border-alert: #b26c09;

  --color-bg-highlight: #fdf1da;

  --shadow-light: 0px 1px 5px -1px rgba(0, 0, 0, 0.14);
  --shadow-medium: 0px 2px 10px -3px rgba(0, 0, 0, 0.16);
  --shadow-heavy: 0px 4px 16px -4px rgba(0, 0, 0, 0.22);

  --transition-light: 0.06s cubic-bezier(0.4, 0, 0.2, 1) all;
  --transition-medium: 0.1s cubic-bezier(0.4, 0, 0.2, 1) all;
  --transition-heavy: 0.2s cubic-bezier(0.4, 0, 0.2, 1) all;

  --radius-small: 2px;
  --radius-medium: 4px;
  --radius-large: 6px;
  --radius-smooth: 8px;

  --checkbox-enlarge: 1.1;
  --checkbox-shrink: 0.9;
}

body[data-theme="dark"] {
  --color-text-strong: #ffffff;
  --color-text-primary: #fcfcfc;
  --color-text-secondary: #aeb3b4;
  --color-text-tertiary: #919798;
  --color-text-accent: #5edbe7;
  --color-text-positive: #34e4a5;
  --color-text-negative: #ff9494;
  --color-text-searchHighlight: #ffc039;

  --color-button-text: #1c2323;
  --color-button-bg: #61cbd4;
  --color-button-bg-secondary: #acd7de;
  --color-button-bg-recording: #d64545;

  --color-bg-primary: #232424;
  --color-bg-secondary: #282d2d;
  --color-bg-tertiary: #2d3636;
  --color-bg-sub: #292929;
  --color-bg-elevated: var(--color-bg-secondary);
  --color-bg-public: var(--color-bg-primary);
  --color-bg-audio-insert: #414949;
  --color-waveform-visualizer: #acd7de;

  --color-bg-accent-primary: #5bc5cf;
  --color-bg-accent-secondary: #48c5d650;
  --color-bg-accent-tertiary: #48c5d630;
  --color-bg-accent-quaternary: #48c5d612;

  --color-bg-positive-primary: #34e4a5;
  --color-bg-positive-secondary: #34e4a550;
  --color-bg-positive-tertiary: #34e4a515;

  --color-bg-negative-primary: #e40c0c;
  --color-bg-negative-secondary: #e40c0c50;
  --color-bg-negative-tertiary: #e40c0c15;

  --color-border-primary: #ffffff30;
  --color-border-secondary: #ffffff20;
  --color-border-secondary-solid: #474849;

  --color-bg-highlight: #c29e5150;

  --transparent: #31313100;

  /*
   * This variable is toggled by a hook that runs when the userSettingsAtom
   * has a change, specifically the `showTodoStrikethrough` property. If it's true
   * we set this variable to have `line-through` else it remains `none`.
   */
  --todo-strikethrough: none;

  letter-spacing: 0.007em;
}

button {
  cursor: pointer;
}

.nonselectable {
  -webkit-touch-callout: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

code {
  font-family: ui-monospace, Menlo, Monaco, "Cascadia Mono", "Segoe UI Mono", "Roboto Mono", "Oxygen Mono",
    "Ubuntu Monospace", "Source Code Pro", "Fira Mono", "Droid Sans Mono", "Courier New", monospace;
}

/* width */
::-webkit-scrollbar {
  width: 6px;
}

/* Track */
::-webkit-scrollbar-track {
  background: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--color-text-tertiary);
  border-radius: 10px;
}

.spinner {
  animation: rotate 2.5s linear 0s infinite normal forwards;
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

#react-joyride-step-0 {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  max-width: 600px;
  margin: auto;
}

#react-joyride-step-0 > .__floater {
  transform: none !important;
  left: unset !important;
  top: unset !important;
  position: unset !important;
  margin-inline: 24px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}

#react-joyride-step-2 > .__floater {
  margin-left: 20px !important;
  margin-right: 6px !important;
}
