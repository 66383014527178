.tree-item {
  display: flex;
  width: 100%;
  position: relative;
  align-items: center;
}

.clone {
  display: inline-block;
  pointer-events: none;
}

.clone > .tree-item {
  border-radius: 6px;
  box-shadow: 0 15px 15px 0 rgba(34, 33, 81, 0.1);
}

.ghost {
  opacity: 0.5;
}

.grab-handle {
  position: absolute;
  left: -10px;
}
