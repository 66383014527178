.editor-div {
  padding: 24px 24px 0;
  background: var(--color-bg-primary);
  width: 100%;
  outline: none;
}

.note {
  /*
    The note gets an arbitrarily large margin on both sides so we can click at the end or the beginning
    of the edit zone and get the caret on the corresponding line. It makes selecting text and positioning the caret easier overall
  */
  margin: 0 auto;
  /* This value is purposefully equal to contentMaxWidth in EditorPage.tsx */
  max-width: 720px;
  box-shadow: 0 1px 0 var(--color-border-secondary) inset; 
  padding: 24px 24px;
  position: relative;
  border-radius: var(--radius-medium);
  word-break: break-word;
  overflow-wrap: break-word;
}

@media (max-width: 600px) {
  .note {
    padding: 24px 16px;
  }
}


.note-unsynced-hidden {
  display: none;
}

.note-unsynced {
  position: absolute;
  top: 30px;
  left: -50px;
  height: 24px;
  padding: 4px 6px 3px;
  z-index: 0;
  border-radius: 50%;
  background: var(--color-bg-negative-tertiary);
}

.note-unsynced:before {
  content: "❗️";
  display: block;
  font-size: 12px;
  color: var(--color-text-negative);
}

.note-unsynced:after {
  opacity: 0;
  content: "Not yet synced";
  background-color: var(--color-bg-primary);
  color: var(--color-text-tertiary);
  top: 1em;
  position: absolute;
  white-space: nowrap;
  left: -120px;
  top: 0;
  transition: opacity 0.3s ease;
}

.note-unsynced:hover:after {
  opacity: 1;
}

.private-editor .note.readAll {
  position: relative;
  box-shadow: 0 2px 0 var(--color-bg-accent-primary) inset,
   0 0px 0 var(--color-bg-accent-primary) inset,
   0 0px 0 var(--color-bg-accent-secondary) inset,
   0 0px 0 var(--color-bg-accent-primary) inset;
  border-radius: var(--radius-large);
  background-color: var(--color-bg-accent-quaternary);

}

body[data-theme="dark"] .private-editor .note.readAll{
  box-shadow: 0 1px 0 var(--color-bg-accent-primary) inset,
  0 0px 0 var(--color-bg-accent-primary) inset,
  0 0px 0 var(--color-bg-accent-secondary) inset,
  0 0px 0 var(--color-bg-accent-primary) inset;
  background-color: var(--color-bg-accent-quaternary);

}

.private-editor.note:first-child {
  box-shadow: none;
}

/* https://web.dev/content-visibility/ introduces bugs, because it assumes the children are all contained in the parent. */

.note:first-child {
  box-shadow: none;
}

.backlinks {
  white-space: initial;
  color: var(--color-text-accent);
  margin-top: 24px;
  user-select: none;
}

.backlinks .backlink-button {
  border: 0;
  background: transparent;
  color: var(--color-text-secondary);
  padding: 2px 4px;
  border: 1px solid var(--color-border-secondary);
  border-radius: var(--radius-medium);
  display: flex;
  gap: 4px;
  align-items: center;
  font-size: 14px;
  cursor: pointer;
  margin-bottom: 8px;
}

.backlinks .backlink-button.expanded {
  background: var(--color-bg-secondary);
}

.backlinks .backlink-button::before {
  content: "▶ ";
  font-size: 10px;
  transform: scaleX(80%);
}

.backlinks .backlink-button.expanded::before {
  content: "▼ ";
  transform: scaleY(80%);
}

.note-debug-section {
  position: absolute;
  right: 0px;
  font-size: 14px;
  padding: 0px 7px;
  z-index: 0;
  border-radius: 8px;
  font-family: monospace;
  letter-spacing: -1px;
  transform: scaleX(0.8) translateX(100%);
  transform-origin: 100% 0%;
}

/* Reference Expansion & backlinks */

.expandedNote {
  background: var(--color-bg-primary);
  box-shadow: 0 1px 0 var(--color-border-secondary) inset,
  2px 0 0 var(--color-border-secondary) inset,
  -1px 0 0 var(--color-border-secondary) inset,
   0 -1px 0 var(--color-border-secondary) inset;
  border-radius: var(--radius-medium);
  position: relative;
  margin: 16px 0;
}

.expandedNote .note {
  border-bottom: 0;
  padding: 12px 24px;
}

.expandedNote .expandedNote::after {
  content: "";
}

.expandedNote.highlighted {
  border-left-color: var(--color-text-accent);
  background: var(--color-bg-accent-quaternary);
}

.expandedNote .note.readAll {
  background: var(--color-bg-primary);
  position: relative;
  margin: 16px 0;
  box-shadow: 0 1px 0 var(--color-bg-accent-secondary) inset,
  1px 0 0 var(--color-bg-accent-primary) inset,
  -1px 0 0 var(--color-bg-accent-secondary) inset,
   0 -1px 0 var(--color-bg-accent-secondary) inset;
}

body[data-theme="dark"] .expandedNote .note.readAll {
  box-shadow: 0 1px 0 var(--color-bg-accent-secondary) inset,
  1px 0 0 var(--color-bg-accent-primary) inset,
  -1px 0 0 var(--color-bg-accent-secondary) inset,
   0 -1px 0 var(--color-bg-accent-secondary) inset;
}

@media (max-width: 600px) {
  .expandedNote {
    margin: 16px 0;
  }
  .expandedNote .note {
    padding: 12px 16px;
  }
}

.note.fromSimon {
  background: var(--color-bg-accent-tertiary);
}

.linkLoader {
  padding: 0px 10px;
}

.dot {
  height: 16px;
  width: 16px;
  right: 0;
  bottom: 5px;
  background-color: red;
  border-radius: 50%;
  position: absolute;
}

.pulsesSource {
  position: absolute;
  inset: 0;
  animation: pulse 1s linear 0s infinite alternate-reverse forwards;
}

.pulsesSource::after {
  content: "";
  border-radius: 10em;
  position: absolute;
  inset: 0;
  outline: solid red 3px;
  transform-origin: 50% 50%;
  animation: scale-up-and-fade 1s linear 0s infinite;
}

@keyframes pulse {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0.2;
  }
}

@keyframes scale-up-and-fade {
  0% {
    transform: scale(0.5);
    clip-path: circle(1.7em);
  }

  100% {
    transform: scale(2);
    clip-path: circle(1.2em);
  }
}

.audio-insert {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  --main-height: 60px;
  --footer-height: 30px;
  height: calc(var(--main-height) + var(--footer-height));
  --audio-vizualizer-bar: #333333;
}

.audio-insert-collapsed {
  height: 20px !important;
}

body[data-theme="dark"] .audio-insert {
  --audio-vizualizer-bar: #e4e4e4;
}

.audio-insert-reveal-btn {
  z-index: 20;
  cursor: pointer;
  color: var(--color-text-accent);
  align-self: start;
}

.audio-insert-content {
  width: 100%;
  position: relative;
}

.audio-insert-view {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  position: relative;
}

.audio-recorder {
  display: flex;
  align-items: center;
  height: var(--main-height);
  width: 100%;
  background-color: rgba(0, 160, 173, 0.1);
  border: 1px solid rgba(0, 160, 173, 0.5);
  padding-inline: 14px;
  border-radius: 5px;
}

.audio-insert-message {
  display: flex;
  align-items: center;
  justify-content: center;
  height: var(--main-height);
  width: 100%;
  background-color: rgba(0, 160, 173, 0.1);
  border: 1px solid rgba(0, 160, 173, 0.5);
  padding-inline: 14px;
  border-radius: 5px;
}

.audio-player {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

.audio-player main {
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  align-items: center;
  height: var(--main-height);
}

.audio-player footer {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding-bottom: 3px;
  height: var(--footer-height);
  overflow-x: hidden;
  white-space: nowrap;
}

.audioInsertButton {
  all: unset;
  text-decoration: underline;
  color: var(--color-text-accent);
  cursor: pointer;
}
.audioInsertButton:active {
  opacity: 0.7;
}

p:has(> .checkbox-wrapper.checked) {
  text-decoration: var(--todo-strikethrough);
}

/* custom audio element */
.rangeslider {
  margin: 0 !important;
  box-shadow: none !important;
  height: 5px !important;
}

.rangeslider__handle-tooltip {
  display: none !important;
}

.rangeslider-horizontal {
  border-radius: 4px !important;
  background-color: var(--color-bg-audio-insert) !important;
}

.rangeslider__fill {
  border-radius: 4px !important;
  box-shadow: none !important;
  background-color: var(--color-bg-accent-primary) !important;
}

.rangeslider__labels {
  display: none !important;
}

.rangeslider__handle::after {
  display: none !important;
}

.rangeslider__handle {
  background: var(--color-text-accent) !important;
  box-shadow: none !important;
  border-radius: 4px !important;
  width: 3px !important;
  height: 14px !important;
  border: none !important;
}
